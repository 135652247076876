@import 'styles/variable.less';

.layer-slider {
  width: 100%;
  .nav-btn {
    position: unset !important;
  }
  .slick-current {
    .layer-slide {
      color: @white !important;
    }
  }
  .layer-slide {
    .flexAlignCenter();
    justify-content: center;
    height: 52px;
    color: @blueshade;
    .fw-400();
    .fs-14();
    line-height: 19px;
    text-align: center;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;