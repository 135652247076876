@import 'styles/variable.less';

.remove-member-org-modal {
  > div:nth-child(2) {
    > button:first-child {
      top: 1px;
      right: 7px;
      > span {
        height: 51px;
        width: 51px;
      }
    }
    > div:nth-child(2) {
      border: 0;
      padding-bottom: 0;
      > div {
        border-bottom: 0.5px solid #878787;
        padding-bottom: 12px;
      }
    }
    > div:nth-child(3) {
      height: 280px;
      padding-top: 0;
      .remove-member-title {
        height: 39px;
        width: 100%;
        border-bottom: 0.5px solid @ghostbtnBack;
        background-color: @back;
        .emp-title {
          .fw-600();
          .fs-12();
          line-height: 14px;
          color: @btnTitle;
        }
      }
      .remove-member-detail {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        .remove-member-avatar {
          .fw-500();
          .fs-28();
          color: @avatarTitle;
          background: @white;
          border: 1px solid @avatarTitle;
        }
        .remove-member-text {
          width: 75%;
          display: block;
          text-align: center;
          margin: 0 auto;
        }
      }
    }
    > div:nth-child(4) {
      direction: rtl;
      > button:first-child {
        padding: 4px 28.5px;
      }
      > button:last-child {
        padding: 4px 33.5px;
        margin-left: 0;
        margin-right: 25px;
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;