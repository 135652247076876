@import 'styles/variable.less';

.main-add-edit-org {
  height: @tlHeight;
  background-color: @white;
  padding: 10px 20px 20px 20px;
  .org-header {
    height: 100%;
    justify-content: flex-start;
    .org-title {
      height: 40px;
      .fw-500();
      .fs-22();
      line-height: 29px;
      color: @titleColor;
      align-items: center;
      padding-bottom: 8px;
    }
    .main-org-list {
      height: calc(100% - 41px);
      background: @white;
      border: 1px solid @primary;
      border-radius: 4px;
      .main-add-org-content {
        height: 100%;
        justify-content: space-evenly;
        align-items: center;
        .org-detail-form-div {
          width: 617px;
          .add-org-form {
            .input-item {
              margin-bottom: 30px;
              .form-input-text {
                .fw-500();
                .fs-18();
                padding: 14.86px 11px;
                color: @primary;
                border: 0.5px solid @blueshade;
                &:focus {
                  box-shadow: none;
                }
              }
              .user-name-div {
                width: 290px;
              }
              .member-select {
                > div {
                  height: 60px;
                  > span:first-child {
                    > input {
                      height: 58px;
                      color: @primary;
                    }
                  }
                  > span:last-child {
                    line-height: 60px;
                    color: @primary;
                    .fw-500();
                    .fs-18();
                  }
                }
              }
            }
          }
        }
        .org-detail-img-div {
          .org-logo-title {
            margin-bottom: 18px;
          }
          .org-logo-img {
            width: 321px;
            height: 323px;
            margin-bottom: 30px;
            background: @lightdarkGray;
            border-radius: 4px;
            .flexAlignCenter();
            justify-content: center;
            cursor: pointer;
            .input-image {
              height: 100%;
              width: 100%;
              display: block;
              > div:first-child {
                height: 100%;
                width: 100%;
                .upload-image-btn {
                  height: 100%;
                  width: 100%;
                  padding: 0;
                }
              }
            }
          }
        }
        .skip-btn-content {
          display: flex;
          justify-content: flex-start;
          margin-top: 50px;
          > button {
            height: 36px;
            background-color: @ghostbtnBack;
            border-radius: 4px;
            padding: 4px 37px;
            .fw-500();
            .fs-18();
            line-height: 21px;
            color: @btnLabel;
          }
        }
        .submit-btn-content {
          display: flex;
          justify-content: flex-end;
          margin-top: 50px;
          > button {
            height: 36px;
            border-radius: 4px;
            padding: 4px 48px;
            .fw-500();
            .fs-18();
            line-height: 21px;
            background-color: @primary;
            color: @white;
          }
        }
      }
    }
  }
}
.crop-image-modal {
  > div {
    > div {
      &:last-child {
        .flexAlignCenter();
        justify-content: space-between;
        input[type='range'] {
          -webkit-appearance: none;
          width: 40%;
          margin-left: 8px;
        }
        input[type='range']:focus {
          outline: none;
        }
        input[type='range']::-webkit-slider-runnable-track {
          width: 100%;
          height: 5px;
          cursor: pointer;
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
          background: @veryLightGray;
          border-radius: 10px;
          border: 0px solid #000101;
        }
        input[type='range']::-webkit-slider-thumb {
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background: @primary;
          cursor: pointer;
          -webkit-appearance: none;
          margin-top: -8px;
        }
        input[type='range']:focus::-webkit-slider-runnable-track {
          background: @veryLightGray;
        }
        input[type='range']::-moz-range-track {
          width: 100%;
          cursor: pointer;
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
          background: @primary;
          border-radius: 25px;
          border: 0px solid #000101;
        }
        input[type='range']::-moz-range-thumb {
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
          border: 0px solid @black;
          height: 20px;
          width: 20px;
          border-radius: 7px;
          background: @primary;
          cursor: pointer;
        }
        input[type='range']::-ms-track {
          height: 5px;
          cursor: pointer;
          background: transparent;
          border-color: transparent;
          border-width: 39px 0;
          color: transparent;
        }
        input[type='range']::-ms-fill-lower {
          background: @primary;
          border: 0px solid #000101;
          border-radius: 50px;
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
        }
        input[type='range']::-ms-fill-upper {
          background: @primary;
          border: 0px solid #000101;
          border-radius: 50px;
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
        }
        input[type='range']::-ms-thumb {
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
          border: 0px solid @black;
          height: 20px;
          width: 39px;
          border-radius: 7px;
          background: @primary;
          cursor: pointer;
        }
        input[type='range']:focus::-ms-fill-lower {
          background: @primary;
        }
        input[type='range']:focus::-ms-fill-upper {
          background: @primary;
        }
        .crop-modal-btn {
          margin-right: 8px;
        }
      }
    }
  }
}
.cropper-content {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  height: 435px;
  background-color: @veryLightGray;
  .cropper-modal {
    background-color: @white;
  }
}
.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;