@import 'styles/variable.less';

.avatar {
  > div {
    width: 30px;
    height: 30px;
    box-shadow: 2px 0px 4px rgba(103, 103, 103, 0.15);
    border-radius: 50%;
    position: relative;
    margin-left: -5px;
    z-index: 1;
    .flexAlignCenter();
    justify-content: center;
    color: @black;
    background-color: @white;
    text-align: center;
    border: 0.5px solid @avatarTitle;
    &:hover {
      background-color: @primary;
      z-index: 2;
      > span {
        color: @white;
        .fslh-12();
      }
    }
    span {
      .flexAlignCenter();
      justify-content: center;
      margin: 0 !important;
      color: @avatarTitle;
      .fs-12();
      line-height: 14px;
    }
    @media (max-width: 1441px) {
      width: 24px;
      height: 24px;
      span {
        font-size: 10px;
      }
    }
    @media (max-width: 1321px) {
      width: 23px;
      height: 23px;
      span {
        font-size: 10px;
      }
    }
  }
  .last-div {
    &:hover {
      background-color: @avatarTitle;
      > span {
        > svg {
          fill: @white !important;
        }
      }
    }
  }
  .first-div {
    > span {
      color: @white;
      .fw-500();
    }
    background-color: @primary !important;
  }
}

.avatars {
  .flexAlignCenter();
  justify-content: left;
  direction: rtl;
  text-align: left;
  cursor: pointer;
}
.avtar-tooltip {
  > div {
    > div:last-child {
      color: @black !important;
      background-color: @secondary !important;
      .avtars-name {
        display: flex !important;
        flex-direction: column !important;
        > span:last-child {
          text-transform: capitalize;
        }
      }
    }
  }
}

.pl-5 {
  padding-left: 5px;
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;