@import 'styles/variable.less';

.main-add-members {
  height: @tlHeight;
  background-color: @white;
  padding: 20px;
  padding-top: 12px;
  .add-member-header {
    padding: 0;
    padding-bottom: 10px;
    .ant-page-header-heading-title {
      .fw-500();
      .fs-22();
      line-height: 30px;
    }
  }
  .add-member-content {
    height: calc(100% - 45px);
    border: 1px solid @primary;
    border-radius: 5px;
    .flexAlignCenter();
    justify-content: center;
    .add-member-form {
      width: 617px;
      .member-form-item {
        .member-select {
          > div {
            height: 60px;
            > span:first-child {
              > input {
                height: 58px;
                color: @primary;
              }
            }
            > span:last-child {
              line-height: 60px;
              color: @primary;
              .fw-500();
              .fs-18();
            }
          }
        }
      }
      .member-form-name {
        width: 100%;
        .flexAlignCenter();
        justify-content: space-between;
        > div {
          width: 47%;
          .member-name {
            padding: 14.87px 11px;
            .fw-500();
            .fs-18();
            color: @primary;
          }
        }
      }
      .member-form-email {
        .member-email {
          padding: 14.87px 11px;
          .fw-500();
          .fs-18();
          color: @primary;
        }
      }
      .add-member-btn {
        .flexAlignCenter();
        justify-content: space-between;
        > button {
          .flexAlignCenter();
          justify-content: center;
          width: 138px;
          height: 36px;
          .fw-500();
          .fs-20();
        }
        > button:first-child {
          color: @btnLabel;
          background-color: @ghostbtnBack;
          .fw-500();
          .fs-18();
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;