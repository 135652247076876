@import 'styles/variable.less';

.edit-file-modal {
  > div:nth-child(2) {
    > button:first-child {
      top: 7px;
      right: 10px;
      > span {
        height: 51px;
      }
    }
    > div:nth-child(2) {
      padding-bottom: 0px;
      border: 0;
      > div {
        padding-bottom: 12px;
        border-bottom: 0.5px solid #878787;
        .fs-20();
      }
    }
    > div:nth-child(3) {
      padding-top: 15px;
      padding-bottom: 0px;
      // padding-bottom: 28px;
      .edit-file-label {
        .fw-600();
        .fslh-12();
        color: @btnTitle;
        margin-bottom: 10px;
      }
    }
    > div:last-child {
      direction: rtl;
      background: #f7f7f7;
      border-radius: 0px 0px 4px 4px;
      > button:last-child {
        margin: 0;
        margin-right: 25px;
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;