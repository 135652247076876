@import 'styles/variable.less';
@import 'styles/image.less';

.main-map-view {
  height: @tlHeight;
  padding: 0 20px;
  background-color: @white;
  .map-view-head {
    height: 56px;
    .flexAlignCenter();
    justify-content: space-between;
    > div:first-child {
      .flexAlignCenter();
      justify-content: flex-start;
      cursor: pointer;
      > span {
        padding-left: 12px;
        .fw-500();
        .fs-22();
        line-height: 26px;
        color: @titleColor;
      }
    }
    > div:nth-child(2) {
      .fw-500();
      .fs-24();
      line-height: 29px;
      color: @titleColor;
    }
    > div:last-child {
      > div {
        > button {
          height: 30px;
          width: 35.5px;
          > span {
            .fw-500();
            .fs-16();
            line-height: 19px;
          }
        }
        > button:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        > button:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
  .map-content {
    height: calc(100vh - 130px);
    border-radius: 4px;
    overflow-y: auto;
  }
}
.map-container {
  height: 100%;
  width: 100%;
  outline: none;
  .leaflet-control-layers {
    // top: calc(100vh / 2 - 37px) !important;
    top: 200px;
    background: rgba(37, 37, 37, 0.75) !important;
    color: @white !important;
    border: none !important;
    opacity: 0;
    z-index: 0;
  }
  .leaflet-control-layers-toggle {
    background-image: url(@layerControlBack) !important;
    width: 28px !important;
    height: 28px !important;
  }
}
.mapview-popup {
  bottom: unset !important;
  left: -15px !important;
  top: 5px !important;
  right: unset !important;
  > div:nth-child(2) {
    display: none;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;