@import 'styles/variable.less';
@import 'styles/image.less';

.custom-layer {
  background-color: #413e39;
  z-index: 999;
  position: absolute;
  top: 65px;
  right: 10px;
  box-shadow: none;
  border-radius: 2px;
  cursor: pointer;
  .layer-dd-content {
    .flexAlignCenter();
    justify-content: space-around;
    height: 29px;
    width: 63px;
  }
}
.switch-btn {
  margin: 0;
  height: 15px;
  border: 1px solid #696969;
  background-color: @white !important;
  min-width: 30px;
  &:focus {
    box-shadow: none;
  }
  > div:first-child {
    left: 3px;
    width: 8px;
    height: 8px;
    &::before {
      background-color: #696969;
    }
  }
}
.switch-btn.ant-switch-checked {
  background-color: @white !important;
  border: 1px solid @green;
  > div:first-child {
    left: calc(100% - 12px) !important;
    width: 8px;
    height: 8px;
    &::before {
      background-color: @green;
    }
  }
  > div:last-child {
    box-shadow: none;
  }
}
.vector-layer {
  width: 100%;
  float: right;
  background: unset;
  border: 0;
  cursor: default;
  .uploaded-layer {
    border-bottom: 0;
    .ant-collapse-arrow {
      > svg {
        transform: rotate(-90deg) !important;
      }
    }
    > div:first-child {
      padding: 10px 15px;
      padding-top: 6px;
      padding-right: 40px;
      height: 37px;
      background: #1a1a1a;
      border-bottom: 0.5px solid @borderclr;
      > div:first-child {
        > span {
          > svg {
            fill: @white;
          }
        }
      }
      > span {
        > span {
          padding-bottom: 5px;
          padding-right: 8px;
          .fw-500();
          .fslh-12();
          color: @white;
        }
        .layer-switch {
          border: 0.5px solid @borderclr;
          background-color: unset;
          > div {
            height: 10px;
            width: 10px;
          }
        }
        .layer-switch.ant-switch-checked {
          background-color: @green;
          > div {
            height: 10px;
            width: 10px;
          }
        }
      }
    }
    > div:last-child {
      height: 33vh;
      border-top: 0;
      background: unset;
      > div {
        height: 97%;
        padding: 0;
        margin: 5px 10px;
        margin-top: 0;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 2px;
          background-color: #666666;
        }
        &::-webkit-scrollbar-thumb {
          background: @veryLightGray;
          border-radius: 3px;
        }
        .vector-layer-content {
          .flexAlignCenter();
          justify-content: space-between;
          margin: 7px 0px;
          > span {
            display: flex;
            cursor: pointer;
            z-index: 999999;
            padding-right: 5px;
          }
          > div {
            .flexAlignCenter();
            .box-color {
              width: 12px;
              height: 12px;
              margin-right: 8px;
              margin-top: 2px;
            }
            .box-color-bee {
              width: 12px;
              height: 3px;
              margin-right: 8px;
            }
            .layer-content-title {
              .fw-500();
              .fs-12();
              color: @white;
              width: 150px;
              margin-right: 3px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
  .added-panel {
    > div:last-child {
      height: 69vh;
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-arrow {
      > svg {
        transform: rotate(90deg) !important;
      }
    }
  }
}
.empty-layers {
  .flexJustifyCenter();
  color: @white;
  margin-top: 5px;
}
.map-action-control {
  position: absolute;
  top: calc(100vh / 2 - 60px);
  right: 0;

  .layer-list-icon,
  .zoom-plus,
  .zoom-minus,
  .polygon-icon,
  .move-icon,
  .map-icon,
  .split-icon,
  .map-layer {
    background-color: rgba(37, 37, 37, 0.75);
    border: 0;
    box-shadow: none;
    height: 28px;
    padding: 5px 0px;
    width: 28px;

    &:hover,
    &:focus {
      box-shadow: none;
      border: 0;
      background-color: rgba(37, 37, 37, 0.75);
    }
  }
  .split-active {
    background-color: #999999;
    &:hover,
    &:focus {
      background-color: #999999;
    }
  }
  .zoom-minus {
    .flexAlignCenter();
    justify-content: center;
    > svg {
      margin-top: 2px;
    }
  }
  .layer-list-icon {
    margin-bottom: 5.6px;
  }
  .zoom-minus,
  .move-icon,
  .polygon-icon,
  .map-icon,
  .split-icon {
    margin-top: 5.6px;
  }
}
.map-view-action-control {
  top: calc(100vh / 2 - 180px);
}
.map-layer {
  margin-bottom: 6px;
  border-radius: 4px;
  .flexAlignCenter();
  justify-content: center;
}
.maplayer-dropdown {
  > ul {
    padding: 0;
    border-radius: 4px;
    background-color: rgba(37, 37, 37, 0.75);
    .ant-dropdown-menu-item {
      padding: 5px;
      &:hover {
        background: unset;
      }
    }
    .ant-typography {
      color: @white;
    }
  }
}
.polygon-tooltip {
  width: 232px;
  background: rgba(24, 24, 24, 0.9);
  border-radius: 4px;
  border: 0;
  &::before {
    border-bottom-color: rgba(24, 24, 24, 0.9);
  }
  .tooltip-title {
    .fw-600();
    .fs-12();
    line-height: 14px;
    color: @notification;
  }
  .tooltip-content {
    .fw-500();
    .fs-12();
    line-height: 14px;
    color: #f2f2f2;
  }
}
.polygon-popup {
  > div:first-child {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    > div:first-child {
      width: 232px !important;
      > div {
        > div {
          > div {
            gap: 0px !important;
          }
        }
      }
      .tooltip-title {
        .fw-600();
        .fslh-12();
        color: #42444a;
      }
      .tooltip-content {
        .fw-500();
        .fslh-12();
        color: #9961f5;
      }
    }
  }
  > div:nth-child(2) {
    > div {
      display: none;
    }
  }
  > a:last-child {
    top: 2px;
    right: 3px;
  }
}

/* Annotation Form */
.annotation-form {
  padding: 12px 15px;
  z-index: 99999;
  .ann-form-label {
    .fw-500();
    .fs-12();
    line-height: 16px;
    color: @black1;
    margin-bottom: 5px;
  }
  .annotation-form-item {
    margin-bottom: 12px;
    .ant-input {
      color: @primary;
      .fw-500();
      .fs-12();
    }
  }
  .annotation-form-button {
    padding: 4px 40px;
  }
  .annotation-form-button:first-child {
    margin-right: 7px;
  }
}

/* Rendor Annotations Markings */
.annts-point-popup {
  left: -10px !important;
  bottom: -245px !important;
  > div:first-child {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    > div {
      margin: 12px 15px;
      border: 0;
      > div {
        gap: 10px;
        .annts-market-title {
          .fw-600() !important;
          .fs-12();
          line-height: 16px;
          color: #42444a;
          display: block;
          .ff();
          letter-spacing: 0.5px;
        }
        .annts-market-data {
          .fw-500() !important;
          .fs-12();
          line-height: 16px;
          color: @primary;
          display: block;
          .ff();
          letter-spacing: 0.5px;
        }
      }
    }
  }
  > div:nth-child(2) {
    display: none;
  }
  > a:last-child {
    top: 3px;
    right: 3px;
    color: @black;
    &:hover {
      color: @black;
    }
  }
}
.risk-point-popup {
  bottom: -180px !important;
}
.risk-marker-popup {
  bottom: -120px !important;
}
.buffer-point-popup {
  bottom: -100px !important;
}
.annts-point-tooltip {
  background-color: transparent;
  padding: 3px;
  padding-top: 0;
  border: 0;
  box-shadow: none;
  top: -5px;
  &::before {
    display: none;
  }
  > button {
    background-color: @white;
    color: @primary;
    .fw-500();
    .fs-10();
    line-height: 14px;
    height: 24px;
    padding: 2px 15px;
    border-color: @white;
  }
  > span {
    color: @lightGreen;
  }
}
path.leaflet-interactive:focus {
  outline: none;
}
/* Layer Control List CSS ---------- Start ---------- */
.risk-line-popup {
  left: -10px !important;
  bottom: -170px !important;
}
.layer-control-list-main {
  background-color: rgba(37, 37, 37, 0.75);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 54px;
  width: 225px;
  height: calc(100vh - 400px);
  overflow: auto;
  z-index: 9999;
  padding: 16px;
  border-radius: 4px;
  .vector-layer {
    .basemap-panel {
      .ant-collapse-arrow {
        right: 11px;
        > svg {
          transform: rotate(-90deg) !important;
        }
      }
      border-bottom: 0;
      > div:first-child {
        padding-top: 6px;
        padding-right: 40px;
        padding-left: 0;
        padding-bottom: 12px;
        height: 35px;
        background-color: transparent;
        border-bottom: 0.5px solid @borderclr;
        > div:first-child {
          > span {
            > svg {
              fill: @white;
            }
          }
        }
        > span {
          padding-bottom: 5px;
          padding-right: 8px;
          .fw-500();
          .fslh-12();
          color: @white;
        }
      }

      .ant-collapse-content {
        border-top: 0;
        background: unset;
        height: auto !important;
        > div:first-child {
          margin: 0 !important;
          padding: 8px 0 !important;
        }
      }
    }
    .ant-collapse-item-active .ant-collapse-arrow {
      > svg {
        transform: rotate(90deg) !important;
      }
    }
  }
}

.vector-layer-content {
  .flexAlignCenter();
  justify-content: space-between;
  .layer-content-title {
    .fw-500();
    .fs-12();
    color: @white;
    width: 150px;
    margin-right: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > span:last-child {
    margin-right: 8px;
    padding: 0px 2px;
    .fs-12();
    .fw-500();
    color: @white;
    > svg {
      cursor: pointer;
    }
  }
  .layer-content-data {
    margin: 0 !important;
    padding: 0 !important;
  }
  > span:last-child.ant-typography-disabled {
    .fs-12();
    .fw-500();
    color: @white;
    > svg {
      cursor: not-allowed;
    }
  }
}
.panel-content-main-card {
  width: 100%;
}
/* Layer Control List CSS ---------- End ---------- */

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;