@import 'styles/variable.less';

.main-reset-pass {
  .login-content {
    .inr-login-content {
      .reset-success {
        height: 100% !important;
        display: unset !important;
        .reset-success-icon {
          margin: 37px 0 !important;
          height: 150px !important;
          .flexJustifyCenter();
        }
        .pass-reset-title {
          padding-left: 20px;
          .fw-600();
          .fs-22();
          line-height: 26px;
          color: @primary;
          margin-bottom: 30px;
        }
        .success-title {
          color: #219653;
          display: block;
          padding: 0 20px;
        }
        .continue-to-login {
          color: #828282;
          display: block;
          padding: 0 20px;
          padding-top: 114px;
          .fs-14();
        }
        .main-continue-btn {
          padding: 0 20px;
          padding-top: 22px;
          .continue-btn {
            height: 50px;
          }
        }
      }

      .reset-pass-form {
        .reset-password-input {
          > div {
            > div:first-child {
              > label {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
        .reset-pass-btn {
          @media (max-width: 1330px) {
            margin-top: 200px !important;
          }
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;