@import 'styles/variable.less';

.download-data-layer {
  .ant-drawer-header,
  .ant-drawer-body,
  .ant-drawer-footer {
    background-color: @roottableth1;
  }
  .ant-drawer-header {
    border-bottom: 0;
    padding-bottom: 0;
    .ant-drawer-header-title {
      flex-direction: row-reverse;
      padding-bottom: 16px;
      .ant-drawer-close {
        padding: 0;
        margin: 0;
      }
      .ant-drawer-title {
        text-align: start;
        font-weight: 500;
        .fs-20();
        .fw-500();
        line-height: 27px;
        color: @white;
      }
    }
  }
  .ant-drawer-body {
    padding-top: 0;
    .download-content {
      border-top: 0;
      height: 100%;
      .download-slider-div {
        border-bottom: 0.5px solid @ghostbtnBack;
        .layer-tab-container-dwn {
          .nav-btn-main-dwn {
            .nav-btns-dwn {
              border: 0;
              &:hover {
                background-color: unset;
              }
            }
            .left-nav-btn-dwn {
              padding-right: 10px;
            }
            .right-nav-btn-dwn {
              padding-left: 10px;
            }
          }
          .layer-tablist-container-dwn {
            align-items: flex-end;
            padding: 0;
            .layer-btn-dwn {
              height: 40px;
              width: 55px;
              border: 0.5px solid @blueshade;
              border-radius: 4px 4px 0px 0px;
              padding: 0 8px;
              margin: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              > span {
                color: #c7c7cc;
                text-align: center;
                font-size: 12px;
                font-weight: 500;
                line-height: 13px;
              }
            }
            .rts___tab___selected {
              width: 62px;
              height: 42px;
              padding: 0px 10px;
              border: 0;
              background-color: @white;
              > span {
                color: #42444a;
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
        }
      }
      .download-data-table {
        > div {
          > div {
            > div {
              > div {
                > div {
                  > table {
                    > thead {
                      > tr {
                        > th {
                          background-color: @roottableth1;
                          border-bottom: 0.5px dashed @tooltiptitle;
                          color: @notification;
                          .fw-400();
                          .fs-16();
                          line-height: 19px;
                          padding: 10.5px 16px;
                          padding-left: 2px;
                          &:last-child {
                            box-shadow: none;
                          }
                          > div {
                            > label {
                              > span {
                                > span {
                                  background-color: @roottableth1;
                                  border-color: @white;
                                  &:focus,
                                  &:hover,
                                  &:focus-visible,
                                  &:focus-within {
                                    border-color: @white;
                                  }
                                }
                              }
                              > span.ant-checkbox-checked {
                                > span {
                                  border-color: @white;
                                  background-color: @white;
                                  &::after {
                                    border-color: @roottableth1;
                                  }
                                }
                              }
                              > span.ant-checkbox-indeterminate {
                                > span {
                                  // background-color: @white;
                                  &::after {
                                    background-color: @roottableth1;
                                    border-color: @white;
                                  }
                                }
                              }
                            }
                          }
                          &:nth-child(2) {
                            padding-left: 8px;
                          }
                        }
                      }
                    }
                    > tbody {
                      > tr {
                        > td {
                          padding: 7.5px 16px;
                          padding-left: 2px;
                          background-color: @roottableth1;
                          border-bottom: 0.5px dashed @tooltiptitle;
                          > span {
                            color: @notification;
                            .fw-400();
                            .fs-16();
                            line-height: 19px;
                          }
                          &:first-child {
                            > label {
                              > span {
                                > span {
                                  background-color: unset;
                                  border: 1px solid @white;
                                  &:hover {
                                    border-color: @white;
                                  }
                                }
                              }
                              > span.ant-checkbox-checked {
                                > span {
                                  background-color: @white;
                                  &::after {
                                    border-color: @roottableth1;
                                  }
                                  &:hover {
                                    border-color: @white !important;
                                  }
                                }
                                &::after {
                                  border: 1px solid @white;
                                }
                              }
                            }
                          }
                          &:last-child {
                            padding-left: 8px;
                          }
                          .ant-empty-description {
                            .fs-20();
                            color: @white;
                          }
                        }
                        &:last-child {
                          > td {
                            border: 0;
                          }
                        }
                      }
                    }
                  }
                  &:last-child {
                    overflow-y: auto !important;
                    &::-webkit-scrollbar {
                      width: 5px;
                    }
                    &::-webkit-scrollbar-thumb {
                      cursor: pointer;
                      background: @tooltiptitle;
                      border-radius: 3px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .ant-drawer-footer {
    .download-foolter {
      .flexAlignCenter();
      justify-content: center;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;