@import 'styles/variable.less';

.view-left-sidebar {
  position: absolute;
  vertical-align: middle;
  z-index: 998;
  width: 225px;
  height: 100%;
  top: 0;
  .flexAlignCenter();
  justify-content: flex-start;
}
.collapse-btn {
  position: absolute;
  right: -25px;
  padding: 10px 0;
  width: 20px;
  height: 40px;
  border-radius: 0 90px 90px 0;
  padding-right: 5px;
  z-index: 1001;
  transition: 225px 0.5s;
}

.collapsed {
  left: 0px;
}

.sidebar-drawer {
  > div:first-child {
    background: rgb(0 0 0 / 0%) !important;
    display: none;
  }
  > div:nth-child(3) {
    width: 230px !important;
    top: 60px;
    box-shadow: none !important;
    border-radius: 0 !important;
    > div {
      background: rgba(44, 44, 44, 0.95);
      border-radius: 0 !important;
      > div {
        > div:first-child {
          padding: 0;
          border: 0;
          position: absolute;
          > div:first-child {
            display: none;
          }
        }
        > div:last-child {
          padding: 0;
          border: 0;
          .drawer-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            .content-list {
              .content-tab {
                height: 50px;
                .flexAlignCenter();
                justify-content: space-between;
                padding-left: 7px;
                padding-right: 15px;
                text-transform: capitalize;
                border-bottom: 0.5px solid @borderclr;
                border-radius: 0 !important;
                &:hover {
                  background-color: @black1;
                }
                > span {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 19px;
                  color: @white;
                }
                > svg {
                  cursor: pointer;
                }
              }

              .content-tab-inr {
                justify-content: space-between !important;
                background-color: unset !important;
              }
              .annotations-tab {
                > div {
                  .annotations-panel {
                    > div:first-child {
                      border-bottom: 0.5px solid @borderclr;
                      padding: 10px 15px;
                      padding-top: 6.5px;
                      padding-right: 40px;
                      height: 38px;
                      background: #1a1a1a;
                      > span {
                        > span {
                          padding-right: 8px;
                          .fw-500();
                          font-size: 13px;
                          line-height: 18px;
                          color: @white;
                        }
                      }
                    }
                    > div:last-child {
                      height: 34vh;
                      > div {
                        height: 97%;
                        overflow: auto;
                        padding: 0;
                        padding-top: 3px;
                        &::-webkit-scrollbar {
                          width: 5px;
                        }
                        &::-webkit-scrollbar-thumb {
                          background: @tooltiptitle;
                          border-radius: 3px;
                        }
                      }
                    }
                    .ant-collapse-arrow {
                      > svg {
                        fill: @white !important;
                        transform: rotate(90deg) !important;
                      }
                    }
                  }
                  .added-panel {
                    > div:last-child {
                      height: 69vh;
                    }
                  }
                  .annotations-panel.ant-collapse-item-active {
                    .ant-collapse-arrow {
                      > svg {
                        fill: @white !important;
                        transform: rotate(-90deg) !important;
                      }
                    }
                  }
                  .upload-panel {
                    border: 0;
                  }
                }
              }
              .active-tab-content {
                margin: 0 15px;
                padding-top: 10px;
                .flexAlignCenter();
                justify-content: space-between;
                > span {
                  color: @red;
                  .fs-12();
                  .fw-500();
                  line-height: 16px;
                  color: @white;
                }
                > div {
                  cursor: pointer;
                }
              }
              .add-annotations {
                height: calc(100% - 50px);
                width: 100%;
                .add-annotations-tabs {
                  height: calc(100% - 65px);
                  .add-tab-content {
                    padding-left: 15px;
                    padding-right: 10px;
                    height: calc(100% - 76px);
                    padding-top: 11px;
                    > span {
                      .fw-500();
                      font-size: 13px;
                      line-height: 18px;
                      color: @white;
                    }
                    .marking-list {
                      height: calc(100% - 20px);
                      overflow: auto;
                      padding-right: 1.5px;
                      &::-webkit-scrollbar {
                        width: 3px;
                      }
                      &::-webkit-scrollbar-thumb {
                        background: @veryLightGray;
                        border-radius: 4px;
                      }
                    }
                  }
                  .full-marking-content {
                    height: calc(100%);
                  }
                }
              }
            }
            .active-tab-list {
              .content-tab {
                justify-content: flex-start;
                background-color: @black1;
                border-bottom: 0;
                > span {
                  padding-left: 8px;
                }
              }
              .content-annotations-tab {
                justify-content: space-between;
                > div:first-child {
                  .flexAlignCenter();
                  > span {
                    .fw-500();
                    .fs-14();
                    line-height: 19px;
                    color: @white;
                    padding-left: 12px;
                  }
                }
                > div:last-child {
                  z-index: 999999;
                  width: 24px;
                  height: 24px;
                  background-color: #686868;
                  border-radius: 3px;
                  .flexAlignCenter();
                  justify-content: center;
                }
                .annotations-icon {
                  background-color: unset !important;
                }
              }
            }
            .project-detail {
              height: 105px;
              display: flex;
              flex-direction: column;
              gap: 5px;
              border-top: 0.5px solid @borderclr;
              padding: 5px 15px;
              > div {
                .flexAlignCenter();
                > span {
                  padding-left: 10px;
                  color: @borderclr;
                  .fw-500();
                  .fs-12();
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }
}

.marker-popup {
  margin-bottom: 0;
  > div:first-child {
    width: 278px;

    background: @white;
    border-radius: 4px;
    > div {
      margin: 0;
      height: 100%;
      width: 100% !important;
      .point-annotation-form {
        > span:first-child {
          display: block;
          padding: 12px 15px;
          .fw-600();
          .fs-16();
          line-height: 22px;
          color: @primary;
          .ff();
          border-bottom: 0.5px solid @borderclr;
          font-style: normal;
        }
      }
    }
  }
  > div:nth-child(2),
  > a:nth-child(3) {
    display: none;
  }
}

.add-polyline-modal {
  > div:nth-child(2) {
    > div:first-child {
      padding: 12px 15px;
    }
    > div:nth-child(2) {
      padding: 0;
    }
  }
}

.annotations-btns {
  padding: 10px 10px 10px 15px;
  border-bottom: 0.5px solid @borderclr;
  .annotations-shape-btns {
    width: 100%;
    .shape-btns {
      .flexAlignCenter();
      justify-content: center;
      background-color: #686868;
      border: 0;
      width: 69px;
      &:hover {
        background-color: #353535;
      }
    }
    .active-shape-btn {
      border: 1px solid @white;
      background-color: @white;
      border-top-left-radius: 3px !important;
      border-bottom-left-radius: 3px !important;
      border-top-right-radius: 3px !important;
      border-bottom-right-radius: 3px !important;
      &:hover {
        background-color: @white;
      }
    }
  }
}
/* Risk Analysis */
.riskanalysis-tab {
  height: calc(100% - 50px);
  .risk-div {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-bottom: 0.5px solid @borderclr;
    &:hover {
      background-color: #f3eefa;
      cursor: pointer;
      > div {
        color: @primary;
        .risk {
          color: @primary;
          letter-spacing: 0.3px;
        }
        svg {
          stroke: @primary;
        }
      }
    }
    > div {
      color: @white;
      .flexAlignCenter();
      justify-content: center;
      .risk {
        padding-left: 10px;
        color: @white;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
      > svg {
        stroke: @white;
      }
    }
  }

  .risk-type {
    .flexJustifyCenter();
    .risk-type-btn {
      height: 32px;
      width: 76px;
      padding: 0;
      border-radius: 0;
      border-bottom: 0.5px solid @borderclr;
    }
    .active-risk-type-btn {
      background-color: #1a1a1a;
    }
    .active-high-risk {
      border-bottom: 2px solid @red1;
      &:hover,
      &:focus,
      &:active {
        color: @red1;
      }
    }
    .active-medium-risk {
      border-bottom: 2px solid @orange1;
      &:hover,
      &:focus,
      &:active {
        color: @orange1;
      }
    }
    .active-low-risk {
      border-bottom: 2px solid @green1;
      &:hover,
      &:focus,
      &:active {
        color: @green1;
      }
    }
  }

  .risk-segment-main {
    padding: 8px 4px 4px 4px;
    height: calc(100% - 141px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: @verylightBlack;
      border-radius: 3px;
    }
  }
  .risk-segment-main-intl {
    height: calc(100% - 107px) !important;
  }
  .search-segment {
    padding: 0 0 8px 0;
    .segment-number {
      background-color: #d9d9d9;
      width: 100%;
      height: 28px;
      .fs-12();
      .fw-500();
      .ff();
      text-align: center;
      border: 0;
    }
  }
  .risk-segment-data-card {
    height: calc(100% - 141px);
  }

  .risk-legend {
    height: 110px;
  }
  .legend-title {
    background-color: #1a1a1a;
    padding: 4px;
    > span {
      color: @white;
    }
  }
  .main-risk-legend-content {
    padding: 7px 10px 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 6.5px;
  }

  /* Risk Legend */
  .risk-legend-content {
    .flexAlignCenter();
    > span {
      color: @white;
      .fs-10();
      .fw-400();
      line-height: 14px;
      padding-left: 7px;
    }
    > span {
      color: @white;
      .fs-10();
      .fw-400();
      line-height: 16px;
    }
  }
}
.risk-box {
  height: 10px;
  width: 10px;
  display: inline-block;
}
.risk-box-1 {
  height: 10px;
  width: 2px;
  display: inline-block;
}
.risk-high {
  background-color: @red1;
}
.risk-medium {
  background-color: @orange1;
}
.risk-low {
  background-color: @green1;
}
.riskanalysis-tab-veg-int {
  height: calc(100% - 55px);
}
/* Risk Popup */
.risk-popup-main {
  top: 84px;
  right: 10px;
  position: absolute;
  z-index: 999;
  background: rgba(44, 44, 44, 0.85);
  padding: 8px 16px;
  border-radius: 4px;
  > span {
    display: block;
    text-align: start;
    color: @white;
    .fs-14();
    .fw-400();
    > span {
      color: @white;
      .fs-14();
      .fw-600();
    }
  }
  > span:first-child {
    > span {
      margin-left: 57px;
    }
  }
  > span:nth-child(2) {
    > span {
      margin-left: 24px;
    }
  }
  > span:last-child {
    > span {
      margin-left: 50px;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;