@import 'styles/variable.less';

.main-organization {
  height: @tlHeight;
  background-color: @white;
  padding: 15px 20px 20px 20px;
  .org-header {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    .org-title {
      .fw-600();
      .fs-22();
      line-height: 29px;
      color: @titleColor;
    }
    .org-btn-content {
      .add-org-btn {
        background: @white;
        border: 1px solid @primary;
        border-radius: 4px;
        box-shadow: none;
        color: @primary;
        &::after {
          display: none;
        }
        &:hover,
        &:focus,
        &:active,
        &:focus-within,
        :target {
          color: @primary;
          outline: 0;
        }
      }
    }
  }
  .main-org-list {
    height: calc(100% - 41px);
    border-radius: 4px;
    padding: 10px 20px;
    margin: 0 !important;
    border: 1px solid @primary;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: @veryLightGray;
      border-radius: 3px;
    }
    .org-card {
      margin: 0;
      width: 24%;
      display: inline-block;
      padding: 10px 10px;
      .inr-org-card {
        position: relative;
        border: 1px solid @notification;
        background: @white;
        border-radius: 4px;
        height: 165px;
        padding: 15px 20px;
        cursor: pointer;
        &:hover {
          border: 0;
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
          .edit-org-btn {
            opacity: 1;
          }
        }
        .edit-org-btn {
          opacity: 0;
          position: absolute;
          top: 0;
          right: 0;
          width: 30px;
          height: 30px;
          background-color: #f2f2f2;
          border-radius: 0px 4px;
          .flexAlignCenter();
          justify-content: center;
          cursor: pointer;
        }
        .org-card-content {
          .org-title {
            text-align: center;
            display: block;
            .fw-600();
            .fs-14();
            line-height: 17px;
            color: @primary;
            padding-bottom: 5px;
          }
          .org-logo {
            height: 85px;
            background-origin: content-box;
            background-repeat: no-repeat;
            object-fit: cover;
            background-position: center;
            background-size: 170px 85px;
            @media (max-width: 1440px) {
              background-size: 100px 85px;
            }
          }
          .project-count {
            .flexAlignCenter();
            justify-content: center;
            margin-top: 10px;
            > span:first-child {
              .fw-500();
              .fs-12();
              line-height: 14px;
              color: @primary;
              padding-right: 10px;
            }
            > span:last-child {
              width: 21px;
              height: 20px;
              .fw-500();
              .fs-12();
              line-height: 14px;
              background: #d7d7d7;
              color: #292929;
              border-radius: 2px;
              .flexAlignCenter();
              justify-content: center;
            }
          }
        }
      }
      .add-org-card {
        .flexAlignCenter();
        flex-direction: column;
        justify-content: center;
        > div:first-child {
          margin-bottom: 10px;
        }
        > div:last-child {
          padding-top: 10px;
          .fw-600();
          .fs-14();
          line-height: 17px;
          color: @btnTitle;
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;