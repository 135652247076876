@import 'styles/variable.less';

.main-repo-table {
  height: calc(100vh - 341px);
  padding-top: 20px;
  .repo-table {
    height: 100%;
    .main-table {
      > div {
        > div {
          > div {
            > div {
              > div {
                > table {
                  > thead {
                    > tr {
                      > th {
                        background-color: @back;
                        padding: 11.5px 16px;
                        .fw-600();
                        .fs-12();
                        line-height: 14px;
                        color: @btnTitle;
                        &::before {
                          display: none;
                        }
                      }
                    }
                  }
                  > tbody {
                    > tr {
                      > td {
                        padding: 14px 16px;
                        .fw-500();
                        .fs-14();
                        line-height: 17px;
                        color: @lightBlack;
                        background-color: @white;
                        border-bottom: 1px solid #b2b2b269;
                        &::before {
                          display: none;
                        }
                        .repo-action {
                          display: flex;
                          align-items: baseline;
                          justify-content: space-evenly;
                          > div, >svg {
                            cursor: pointer;
                          }
                        }
                        .update-on-content {
                          color: @lightBlack;
                          font-size: 14px;
                          font-weight: 400;
                        }
                      }
                    }
                  }
                }
              }
              > div:nth-child(2) {
                overflow-y: auto !important;
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;