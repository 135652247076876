@import 'styles/variable.less';

.main-forgot-pass {
  .forgot-pass-main {
    padding: 96px 105px;
    @media (max-width: 1024px) {
      padding: 45px 50px;
    }
    .forgot-pass {
      display: flex;
      align-items: flex-start !important;
      justify-content: flex-end !important;
      width: 100%;
      height: 100px;
      flex-direction: column;
      > span:first-child {
        .fw-600();
        .fs-22();
        line-height: 26px;
        color: @primary;
        text-shadow: none;
      }
      > span:last-child {
        .fw-500();
        .fslh-14();
        color: @tooltiptitle;
        padding-top: 3px;
      }
    }
    .forgot-pass-form {
      padding: 0 !important;
      padding-top: 40px !important;
      padding-bottom: 18px !important;
      position: relative;
      .email-input {
        margin-bottom: 20px !important;
      }
      .form-status {
        position: absolute;
        max-height: 51px;
        min-height: 51px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        > span {
          .fw-500();
          .fs-14();
          line-height: 17px;
        }
      }
      .reset-pass-btn {
        margin-top: 265px !important;
        @media (max-width: 1330px) {
          margin-top: 210px !important;
        }
      }
      .back-to-login {
        .flexJustifyCenter();
        > button {
          padding: 0;
          color: #808080;
          > span {
            .fw-400();
            font-size: 12px;
            text-decoration: underline;
          }
        }
      }
    }
    .bottom-text {
      text-align: center;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;