@import 'styles/variable.less';

.main-satellite-map {
  display: flex;
  justify-content: space-between;
  position: relative;
  .left-sidebar {
    width: 230px;
    background-color: rgba(24, 24, 24, 0.9);
    height: @tlHeight;
  }
  .split-satellite-map {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .compare-map {
    .leaflet-control-attribution {
      display: none !important;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;