@import 'styles/variable.less';

.main-header {
  height: 60px !important;
  line-height: 60px !important;
  padding-inline: 0 !important;
  color: unset !important;
  background-color: #42444a;
  box-shadow: 0px 1px 10px rgba(92, 92, 92, 0.15);
  .main-header-content {
    .header-logo {
      .flexAlignCenter();
      justify-content: flex-start;
      padding-left: 20px;
      cursor: pointer;
    }
    .header-text {
      .flexAlignCenter();
      justify-content: center;
      .fw-700();
      .fs-24();
      line-height: 29px;
      color: @white;
      letter-spacing: 2px;
    }
    .header-user-detail {
      .flexAlignCenter();
      justify-content: flex-end;
      padding-right: 20px;
      .user-icon {
        .flexAlignCenter();
        justify-content: center;
        height: 30px;
        width: 30px;
        > span {
          line-height: 0px;
        }
      }
      .ant-dropdown-open {
        background-color: @white;
        border-radius: 50%;
        > span {
          > svg {
            > path {
              fill: @primary !important;
            }
          }
        }
      }
    }
  }
}
.profile-setting {
  > span {
    padding-left: 13px;
    .fw-500();
    .fs-14();
    line-height: 17px;
    color: @tooltiptitle;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;