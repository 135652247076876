@import 'styles/variable.less';
.risk-drawer {
  // position: absolute;
  top: 60px;
  height: calc(100vh - 60px);
  > div:nth-child(3) {
    height: 100%;
    > div {
      height: 100%;
      background-color: unset;
      > div {
        > div:first-child {
          padding: 0;
          border: 0;
          .ant-drawer-header-title {
            display: none;
          }
          > div:last-child {
            width: 100%;
            .table-main-header {
              background-color: #1a1a1a;
              .flexAlignCenter();
              justify-content: space-between;
              > div:first-child {
                color: @white;
                padding: 14px 0 13px 22px;
                .fs-16();
                .fw-500();
                line-height: 22px !important;
              }
              > div:nth-child(2) {
                > span {
                  .fs-14();
                  font-weight: 600 !important;
                  line-height: 19px;
                  padding: 15px 5px 15px 20px;
                  padding: 0 16px 0 16px;
                  color: @white;
                }
              }
              > div:last-child {
                .flexAlignCenter();
                cursor: pointer;
                > svg {
                  margin-right: 20px;
                }
              }
            }
          }
        }
        > div:last-child {
          padding: 0;
          .risk-table-main {
            height: 100%;
            overflow: hidden;
            padding: 9px 21px;
            background: rgba(44, 44, 44, 0.85);
            > div:first-child {
              height: 28px;
              background: unset;
              .main-risk-btn {
                padding: 0 28px 4px 29px;
                height: 28px;
                border-radius: 2px;
                > span {
                  .fs-10();
                  .fw-600();
                  line-height: 14px !important;
                }
              }
              .all-risk {
                color: @white;
              }
              .active-risk-btn {
                color: @primary;
                background: #1a1a1a;
                border-bottom: 2px solid @primary;
              }
            }
            .main-risk-table {
              height: calc(100vh - 60px);
              .risk-analysis-table {
                height: calc(100% - 100px);
                > div {
                  > div {
                    > div {
                      > div {
                        > div {
                          &:last-child {
                            background-color: #191919cc;
                            height: calc(100vh - 217px) !important;
                            border: 1px solid #6f6f6f;
                            overflow-y: auto !important;
                          }
                          > table {
                            border-collapse: collapse !important;
                            > thead {
                              > tr {
                                > th {
                                  padding: 8px;
                                  color: @white;
                                  background-color: #666666;
                                  text-align: center;
                                  .fw-500();
                                  .fs-14();
                                  border-bottom: none;
                                  line-height: 19px;
                                  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
                                }
                                :nth-child(6),
                                :nth-child(7),
                                :nth-child(8),
                                :nth-child(9),
                                :nth-child(10) {
                                  color: @blueshade;
                                }
                              }
                            }
                            > tbody {
                              > tr:first-child {
                                > td {
                                  padding: 0 !important;
                                }
                              }
                              > tr {
                                &:nth-child(even) {
                                  background-color: #2f2f2f !important;
                                }
                                &:nth-child(odd) {
                                  background-color: #414141 !important;
                                }
                                > td {
                                  color: @white !important;
                                  line-height: 16px;
                                  padding: 14px !important;
                                  border-bottom: none;
                                  min-height: 44px;
                                  .fs-12();
                                  .fw-400();
                                  text-align: center;

                                  &:nth-child(3) {
                                    text-transform: uppercase;
                                    font-weight: 600 !important;
                                  }
                                  &:nth-child(4) {
                                    font-weight: 500 !important;
                                  }
                                  &:nth-child(7) {
                                    text-transform: uppercase;
                                  }
                                }
                              }
                            }
                          }
                        }
                        > div:last-child {
                          &::-webkit-scrollbar {
                            width: 5px;
                            background-color: #666666;
                          }
                          &::-webkit-scrollbar-thumb {
                            background: @veryLightGray;
                            border-radius: 3px;
                          }
                        }
                      }
                    }
                  }
                }
                .ant-table-tbody > tr.ant-table-row:hover > td,
                .ant-table-tbody > tr > td.ant-table-cell-row-hover {
                  background-color: unset !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.high-risk {
  color: @red1;
  &:hover {
    color: @red1;
  }
}
.medium-risk {
  color: @orange1;
  &:hover {
    color: @orange1;
  }
}
.low-risk {
  color: @green1;
  &:hover {
    color: @green1;
  }
}
.active-risk-category {
  color: @verylightBlack;
  &:hover {
    color: @verylightBlack;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;