@import 'styles/variable.less';

.project-view-header {
  height: 60px;
  width: 100%;
  background: rgba(44, 44, 44, 0.75);
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  z-index: 1000;

  .home-back {
    .flexJustifyStartAlignCenter();
    > div {
      .flexAlignCenter();
      cursor: pointer;
      margin-left: 20px;
    }
    .vertical-line {
      margin: 0 24px;
      height: 1px;
      height: 28px;
      border: 1px solid @blueshade;
    }
    > span {
      .fw-600();
      .fs-20();
      letter-spacing: 0.4px;
      color: @white;
    }
  }
}
.projectview-subheader {
  height: 60px;
  width: 100%;
  background: rgba(44, 44, 44, 0.75);
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1000;

  > div:nth-child(2) {
    .layer-tab-container {
      width: 250px;
      .nav-btn-main {
        .nav-btns {
          border: 0;
          color: @white;
          border-radius: 0;
          &:hover {
            background-color: unset;
          }
        }
        .left-nav-btn {
          margin-right: 12px;
        }
        .right-nav-btn {
          margin-left: 12px;
        }
      }
      .layer-tablist-container {
        padding: 0;
        .layer-btn {
          background-color: unset;
          border: 0;
          box-shadow: none;
          border-radius: 0;
          padding: 0 2px;
          margin: 0 12px;
          line-height: 20px;
          > span {
            color: @blueshade;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
          }
        }
        .rts___tab___selected {
          > span {
            color: @white;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
    .center-layer{
      justify-content: center;
    }
  }
  > div:last-child {
    .flexAlignCenter();
    justify-content: flex-end;
    > div {
      height: 40px;
      width: 40px;
      .flexAlignCenter();
      justify-content: center;
      cursor: pointer;
      margin-right: 30px;
      border-radius: 4px;
      &:last-child{
        margin-right: 20px;
      }
      &:hover {
        background-color: @roottableth1;
      }
    }
  }
}
.project-compare-header {
  > div:nth-child(2),
  > div:nth-child(3) {
    .flexJustifyCenter();
  }
  > div:last-child {
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
    padding-right: 23px;
    > div {
      width: 40px;
      height: 40px;
      .flexJustifyAlignCenter();
      &:hover {
        background-color: @roottableth1;
        border-radius: 4px;
      }
    }
  }
}
.add-annts-tooltip {
  position: absolute;
  bottom: -90px;
  height: 80px;
  width: 233px;
  background-color: @white;
  border-radius: 4px;
  align-items: baseline !important;
  .arrow-up {
    position: absolute;
    width: 13px;
    height: 13px;
    background-color: @white;
    border-radius: 4px;
    rotate: (45deg);
    top: -7px;
    left: 50%;
  }
  .tooltip-content {
    padding: 0 12px;
    padding-top: 10px;
    > span {
      display: block;
      .fw-500();
      .fs-12();
      line-height: 16px;
      color: @orange;
    }
    > div {
      text-align: end;
      > button {
        .fw-600();
        color: @primary;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
}

.hide-slider {
  opacity: 0;
}
.show-slider {
  opacity: 1;
  .flexJustifyCenter();
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;