@import 'styles/variable.less';

/* Required Title*/
.input-label {
  width: 100%;
  .fw-500();
  .fs-20();
  line-height: 24px;
  color: @titleColor;
}

/*User Detail*/
.user-detail {
  width: 229px;
  .flexAlignCenter();
  justify-content: flex-start;
  border-bottom: 1px solid @tooltiptitle;
  padding-bottom: 7px;
  .user-avatar {
    width: 42px;
    height: 42px;
    line-height: 41px;
    background-color: @primary;
    .border-1();
    > span {
      .fw-500();
      .fs-20();
    }
  }
  .user-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 10px;
    .user-name {
      .fw-500();
      .fs-16();
      line-height: 19px;
      color: @primary;
    }
    .user-role {
      .fw-400();
      .fslh-14();
      color: @primary;
    }
  }
}

/* Annotations Card */
.tab-inr-content {
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 4px;
  border-bottom: 0.5px solid @borderclr;
  .tab-content-head {
    .flexAlignCenter();
    justify-content: space-between;
    margin-right: 5px;
    > div:first-child {
      .flexAlignCenter();
      justify-content: flex-start;
      > div {
        padding-left: 8px;
        display: flex;
        flex-direction: column;
        > span:first-child {
          .fw-500();
          .fs-12();
          line-height: 16px;
          color: @ghostbtnBack2;
        }
        > span:last-child {
          .fw-400();
          .fs-10();
          line-height: 14px;
          color: @ghostbtnBack1;
          letter-spacing: 0.5px;
        }
      }
    }
    > div:last-child {
      .flexAlignCenter();
      justify-content: flex-end;
      padding-right: 5px;
      cursor: pointer;
    }
  }
  .tab-content-desc {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 15px;

    > span {
      .fw-400();
      .fs-10();
      line-height: 14px;
      color: @ghostbtnBack1;
    }
  }
}
/* Add Annotations Tab Card */
.add-tab-inr-content {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px 10px 0px 15px;
  > div:first-child {
    .flexAlignCenter();
    justify-content: space-between;
    > span:first-child {
      .fw-500();
      .fs-12();
      line-height: 16px;
      color: @white;
    }
    > span:last-child {
      cursor: pointer;
      padding-right: 8px;
    }
  }
  > div:last-child {
    padding-right: 5px;
    .fw-400();
    .fs-10();
    line-height: 14px;
    color: @ghostbtnBack2;
    padding-bottom: 9px;
    border-bottom: 0.5px solid @borderclr;
  }
}

.marking-card {
  margin: 8px 0;
  border: 0.5px solid @white;
  border-radius: 2px;
  padding: 6px 8px;
  padding-right: 6px;
  .flexAlignCenter();
  justify-content: space-between;
  > span {
    .fw-500();
    .fs-12();
    line-height: 16px;
    color: @white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > div {
    .flexAlignCenter();
    justify-content: space-around;
    > svg {
      cursor: pointer;
    }
    > svg:not(:last-child) {
      margin-right: 12px;
    }
  }
  .client-data {
    > svg:not(:last-child) {
      cursor: not-allowed;
    }
  }
  .deactive-shape {
    > svg:first-child {
      cursor: not-allowed;
    }
  }
}

/* Risk segment card */
.risk-segment {
  position: relative;
  border: 0.5px solid @borderclr;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 8px 8px 0;
  > span:first-child {
    color: @white;
    .fs-12();
  }
}
.risk-segment-intl {
  padding-bottom: 8px;
  cursor: pointer;
}
.lat-long-content {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  > div {
    .flexAlignCenter();
    > :last-child {
      cursor: pointer;
    }
  }
  .lat-long {
    line-height: 14px;
    color: @white;
    .fs-10();
    .fw-500();
    margin-left: 5px;
    margin-right: 5px;
  }
  > svg:nth-child(3) {
    cursor: pointer;
  }
  .copied {
    color: @white;
    font-size: 10px;
    line-height: 14px;
    margin-left: 5px;
    font-weight: 500;
  }
}
.error-content {
  display: flex;
  justify-content: space-between;
  margin-left: 2px;
  padding-bottom: 6px;
  :first-child {
    .flexAlignCenter();
    .fs-10();
    .fw-400();
    line-height: 14px;
  }
  :last-child {
    .flexAlignCenter();
    .fs-12();
    .fw-500();
    line-height: 14px;
  }
}

/* Risk Segment Data Card */
.risk-segment-data-content {
  border: 0.5px solid @borderclr;
  border-radius: 4px;
  padding: 8px 6px;
  .risk-data-content-card {
    padding: 0px 3px;
    display: flex;
    gap: 6px;
    align-items: flex-start;
    flex-direction: column;
  }
  > div:first-child {
    > span:first-child {
      color: @white;
      .fs-12();
      .fw-500();
      line-height: 16px;
    }
  }
  .risk-data-content {
    padding: 0px 3px;
    display: flex;
    gap: 6px;
    align-items: flex-start;
    flex-direction: column;
  }
  > div:first-child {
    > span:first-child {
      color: @white;
      .fs-12();
      .fw-500();
      line-height: 16px;
    }
  }
  .content-card {
    width: 100%;
    .flexAlignCenter();
    justify-content: space-between;
    > span:first-child {
      color: @white;
      .fs-10();
      .fw-400();
      line-height: 14px;
      padding-bottom: 4px;
    }
    > span:last-child {
      color: @white;
      .fs-12();
      .fw-500();
      line-height: 16px;
    }
  }
}
.risk-segment-data-content-intl {
  border: 0;
}
/* Full Overlay Loader  */
.full-overlay-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  .flexJustifyAlignCenter();
  flex-direction: column;
  z-index: 10000;
  background: rgba(3, 3, 3, 0.7);
  > span {
    color: @white;
    .fs-20();
    .fw-700();
    line-height: 32px;
    letter-spacing: 0.4px;
  }
  > div {
    .flexJustifyCenter();
    > div {
      width: 200px;
      > div {
        padding: 1px;
      }
    }
  }
  .ant-progress-bg {
    &::before {
      animation: ant-progress-active1 1.5s cubic-bezier(0.23, 1, 0.32, 1) infinite !important;
    }
  }
}

@keyframes ant-progress-active1 {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.8;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}

/* Switch Button V1 */
.layer-switch {
  border: 1px solid @borderclr !important;
  background-color: unset !important;
  > div {
    height: 10px !important;
    width: 10px !important;
  }
}
.layer-switch.ant-switch-checked {
  background-color: @primary !important;
  border: 1px solid @primary !important;
  .ant-switch-handle {
    left: calc(100% - 12px);
  }
  &:focus {
    box-shadow: none !important;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;