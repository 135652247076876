@import 'styles/variable.less';

.project-list-main {
  height: @tlHeight;
  background-color: @white;
  padding: 0 21px 15px 21px;
  .project-list-header {
    padding: 0;
    > div {
      height: 56px;
      align-items: center;
      > span:last-child {
        > div:first-child {
          gap: 22px !important;
        }
      }
    }
    .ant-page-header-back {
      margin: 0;
    }
    .ant-page-header-heading-title {
      padding-left: 12px;
      .fw-600();
      .fs-22();
      line-height: 31px;
      color: @titleColor;
    }
    .create-peoject-btn,
    .user-mng-btn {
      background-color: @white;
      border: 0.5px solid @primary;
      border-radius: 4px;
      padding: 4px 21px;
      > span {
        .fw-500();
        .fs-16();
        line-height: 19px;
        color: @primary;
      }
    }
    .create-peoject-btn {
      height: 30px;
      &:hover {
        background-color: @primary;
        > span {
          color: @white;
        }
      }
    }
    .user-mng-btn {
      border: 0;
      width: 180px;
      &:hover {
        border: 0.5px solid @primary;
      }
    }
    .list-map-view {
      > button {
        height: 30px;
        width: 35.5px;
        > span {
          .fw-500();
          .fs-16();
          line-height: 19px;
        }
      }
    }
    .project-search {
      width: 265px;
      border-radius: 4px;
    }
  }
  .project-list {
    height: calc(100vh - 134px);
    border: 0.5px solid @btnTitle;
    border-radius: 4px;
    position: relative;
    > header {
      min-height: 0px;
      padding: 0;
    }
    > div:nth-child(2) {
      max-height: 100% !important;
      height: 96% !important;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: @veryLightGray;
        border-radius: 3px;
      }
      > div {
        height: 100% !important;
        > div {
          height: 100% !important;
          > div:first-child {
            background-color: @darkGray;
            padding: 0 20px;
            z-index: 2;
            @media (max-width: 1441px) {
              padding: 0px;
            }
            > div {
              background-color: @darkGray;
              border: 0;
              > div {
                padding-left: 0px;
                padding-right: 0px;
                > div {
                  .fs-14();
                  font-weight: bold;
                  padding-left: 3px;
                  color: @btnTitle;
                  text-shadow: none;
                  &:hover {
                    color: @btnTitle;
                    text-shadow: none;
                    opacity: 1;
                  }
                  svg {
                    opacity: 1;
                    fill: @btnTitle;

                    &:hover {
                      opacity: 1;
                    }
                  }
                  span {
                    text-shadow: none;
                    color: @btnTitle;
                    padding-left: 5px;

                    &:hover {
                      color: @btnTitle;
                      text-shadow: none;
                      opacity: 1;
                    }
                  }
                }
                &:first-child,
                &:last-child {
                  justify-content: center;
                }
                &:not(:last-child) {
                  @media (max-width: 1440px) {
                    padding-left: 10px;
                  }
                }
              }
            }
          }
          > div:nth-child(2) {
            display: block;
            padding: 20px;
            @media (max-width: 1441px) {
              padding: 10px;
            }
            > div {
              background: @white;
              border: 0.5px solid @primary;
              border-radius: 4px;
              margin-bottom: 10px;
              height: 75px;
              &:hover {
                background: #6947c61a;
              }
              .project-action {
                .flexAlignCenter();
                gap: 8px;
              }
              > div {
                justify-content: center;
                cursor: pointer;
                .fw-500();
                .fs-14();
                line-height: 17px;
                color: @avatarTitle;
                .editAccess {
                  font-style: normal;
                  .fw-500();
                  .fs-14();
                  line-height: 17px;
                  color: @avatarTitle;
                }
                .start-end-point {
                  .point-name {
                    display: block;
                    .fw-500();
                    .fs-14();
                    color: @avatarTitle;
                  }
                  .point-coord {
                    display: block;
                  }
                }
                .last-updated {
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  .project-dateTime {
                    .fw-500();
                    .fs-14();
                    line-height: 17px;
                    color: @avatarTitle;
                  }
                }
                .avatarGrpContent {
                  > span {
                    background-color: @white;
                    border: 1px solid @avatarTitle;
                    color: @avatarTitle;
                    cursor: pointer;
                    &:hover {
                      background-color: @avatarTitle;
                      color: @white;
                    }
                  }
                }
              }
              > div {
                &:first-child,
                &:last-child {
                  justify-content: center;
                }
                &:last-child {
                  padding-right: 0;
                }

                justify-content: left;

                &:first-child {
                  padding-left: 0;
                  padding-right: 32px;
                  .fw-500();
                  .fs-14();
                  line-height: 17px;
                  color: @avatarTitle;
                  @media (max-width: 1441px) {
                    padding-right: 0px;
                  }
                }
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                  > div {
                    > span {
                      padding-top: 5px;
                      .fw-500();
                      .fs-14();
                      line-height: 17px;
                      color: @avatarTitle;
                      @media (max-width: 1441px) {
                        padding-top: 5px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .main-project-footer {
      height: 34px;
      background: @darkGray;
      border-radius: 0px 0px 4px 4px;
      .flexAlignCenter();
      justify-content: flex-start;
      gap: 16px;
      position: absolute;
      left: 0;
      bottom: 1px;
      width: 99.85%;
      margin: 0 1px;
      z-index: 2;
      div {
        .flexAlignCenter();
        justify-content: flex-start;
        span:first-child {
          padding: 0 10px;
          color: #5c5c5c;
          .fw-500();
          .fs-12();
          line-height: 14px;
          @media (max-width: 1200px) {
            padding: 0 5px;
            font-size: 11px;
          }
        }
        span:last-child {
          padding: 5px 7px;
          background-color: @verylightBlack;
          background: #d7d7d7;
          border-radius: 2px;
          .fw-500();
          .fs-12();
          line-height: 14px;
          @media (max-width: 1200px) {
            padding: 5px;
            font-size: 11px;
          }
        }
      }
      .companyProjectContent {
        padding-left: 10px;
        > span:first-child {
          color: @primary;
        }

        > span:last-child {
          margin-right: 8px;
        }
      }
    }
  }
}
.tooltip-title {
  padding-top: 8px !important;
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: @white;
  }
  .ant-tooltip-inner {
    background-color: @white;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    padding: 4px 10px;
    > span {
      .fw-400();
      .fs-12();
      line-height: 14px;
      color: @tooltiptitle;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;