@import 'styles/variable.less';

.main-setting {
  height: @tlHeight;
  background-color: @white;
  padding: 20px;
  padding-top: 10px;
  .setting-header {
    padding: 0;
    padding-bottom: 10px;
  }
  .setting-content {
    height: calc(100% - 50px);
    border: 1px solid @primary;
    border-radius: 4px;
    .setting-tab {
      height: 100%;
      > div:first-child {
        min-width: 183px;
        > div:first-child {
          > div {
            border: 1px solid @tooltiptitle;
            border-left: 0;
            border-top: 0;
            border-bottom: 0;
            padding-top: 20px;
            > div:last-child {
              display: none;
            }
            > div {
              padding: 10px 24px;
              padding-left: 40px;
              margin: 0;
              margin-top: 14px;
              > div {
                width: 100%;
                text-shadow: none;
                text-align: start;
                .fw-600();
                .fs-16();
                text-shadow: none;
              }
            }
          }
        }
      }
      > div:last-child {
        > div {
          height: 100%;
          .content-tab {
            height: 100%;
            padding: 0;
            .tab-inr-content-row {
              height: 100%;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              > div:first-child {
                text-align: center;
                .user-avatar {
                  position: relative;
                  .user-image {
                    width: 170px;
                    height: 170px;
                    overflow: hidden;
                    border: 1px solid;
                  }
                  > span {
                    > span:first-child {
                      background: @secondary;
                      > span {
                        .fw-600();
                        font-size: 65px;
                        letter-spacing: 0.05em;
                        color: @primary;
                      }
                    }
                    .user-edit {
                      width: 26px;
                      height: 26px;
                      position: absolute;
                      .flexAlignCenter();
                      justify-content: center;
                      background-color: @primary;
                      border-radius: 50%;
                      right: 19px;
                      top: 136px;
                      cursor: pointer;
                    }
                  }
                }
                > span:last-child {
                  .fw-500();
                  .fs-18();
                  line-height: 21px;
                  color: @primary;
                }
              }
              .member-form {
                text-align: center;
                .member-form-name {
                  width: 100%;
                  margin-bottom: 32px;
                  .flexAlignCenter();
                  justify-content: space-between;
                  > div {
                    .member-label {
                      margin-bottom: 9px;
                      text-align: start;
                      .fw-500();
                      .fs-18();
                      line-height: 21px;
                      color: @formLabel;
                    }
                    .member-form-item {
                      margin: 0;
                      .member-input {
                        padding: 12.5px 11px;
                        background-color: @secondary;
                        border-radius: 2px;
                        .fw-500();
                        .fs-18();
                        line-height: 21px;
                        color: @primary;
                      }
                    }
                  }
                }
                .member-form-btn {
                  text-align: start;
                  .member-btn {
                    height: 48px;
                    padding: 4px 44.5px;
                    > span {
                      .fw-500();
                      .fs-20();
                      text-shadow: none;
                    }
                  }
                }
              }
              .main-change-password {
                .memebr-pass-form {
                  > div {
                    margin-bottom: 32px;
                    .member-label {
                      margin-bottom: 9px;
                      text-align: start;
                      .fw-500();
                      .fs-18();
                      line-height: 21px;
                      color: @formLabel;
                    }
                    .member-old-pass {
                      .flexAlignCenter();
                      justify-content: space-between;
                    }
                    .member-pass-item {
                      width: 76%;
                      margin-bottom: 0px;
                      .member-pass-input {
                        padding: 9px 11px;
                        .fw-500();
                        .fs-18();
                        line-height: 21px;
                        > input {
                          color: @primary;
                        }
                      }
                    }
                  }
                  .member-form-btn {
                    text-align: start;
                    margin-bottom: 0px;
                    margin-top: 0px;
                    > button {
                      width: 153px;
                      height: 48px;
                      > span {
                        .fw-500();
                        .fs-20();
                      }
                    }
                  }
                }
              }
              .main-condition-password {
                top: -79px;
                .condition-label {
                  .fw-500();
                  .fs-20();
                  line-height: 24px;
                  color: @titleColor;
                }
                > ul {
                  padding-inline-start: 16px;
                  > li {
                    .fw-400();
                    .fs-18();
                    line-height: 21px;
                    color: #606060;
                    margin-bottom: 18px;
                  }
                }
              }
              .coming-soon {
                font-size: 42px;
                color: @primary;
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;