@import 'styles/variable.less';

.data-layer {
  margin-bottom: 12px;
  .flexAlignCenter();
  justify-content: flex-start;
  > span {
    .fw-600();
    .fs-20();
    line-height: 24px;
    color: @titleColor;
  }
  > button {
    margin-left: 15px;
    height: 26px;
    width: 141px;
    .flexAlignCenter();
    justify-content: space-between;
    padding: 4px 10px;
    border-radius: 2px;
    border: 0.5px solid @primary;
    background-color: @white;
    > span {
      .fw-500();
      .fs-16();
      line-height: 19px;
      color: @primary;
    }
  }
}
.data-layer-date {
  height: 54px;
  max-width: 100%;
  display: inline-flex;
  overflow-x: auto;
  align-items: center;
  &::-webkit-scrollbar {
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: @veryLightGray;
    border-radius: 1px;
  }
  .date-layer-div {
    display: inline-flex;
    height: 100%;
    .datalayer-date-btn {
      border: 0.5px solid @ghostbtnBack;
      width: 150px;
      cursor: pointer;
      .flexAlignCenter();
      justify-content: space-between;
      padding: 5px;
      padding-left: 12px;
      padding-right: 5px;
      border-radius: 4px 4px 0 0;
      > span:first-child {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-right: 2px;
        width: 100%;
        > span {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: @tooltiptitle;
        }
      }
      > span:last-child {
        display: none;
        > a {
          .flexAlignCenter();
          justify-content: center;
          background: @borderclr;
          border-radius: 3px;
          height: 24px;
          width: 24px;
          cursor: pointer;
        }
      }
      &:hover {
        background-color: @ghostbtnBack;
        .disabled-text {
          display: none;
        }
      }
      .disabled-text {
        position: absolute;
        bottom: 0px;
        right: 0px;
        color: #8f8f8f !important;
        font-size: 12px !important;
      }
    }
    .layer-date {
      height: 100%;
      width: 150px;
      background-color: @white;
      border-radius: 2px 2px 0px 0px;
      padding: 8px 12px;
      .flexAlignCenter();
      cursor: pointer;
      white-space: inherit;
      text-align: start;
      color: @primary;
      &:hover {
        background-color: @primary;
        transition: 0.5s;
        > svg {
          fill: @white !important;
        }
        > span {
          color: @white;
        }
      }
    }
    .active-date {
      background-color: @primary;
      border-color: @primary;
      box-shadow: 4px 2px 8px rgba(0, 0, 0, 0.15);
      color: @white;
      > span {
        > span {
          color: @white !important;
          padding-left: 0px;
        }
      }
      &:hover {
        background-color: @primary;
        > span:last-child {
          display: block;
          > a {
            background: @white;
          }
        }
      }
    }
    .disable-active-date {
      background-color: @borderclr !important;
      border-color: @ghostbtnBack !important;
      > span {
        > span {
          color: @tooltiptitle !important;
        }
      }
    }
  }
}
.main-repo-form {
  height: 107px;
  background: @white;
  .border-1();
  border-radius: 0 4px 4px 4px;
  .repo-form {
    padding: 15px 30px;
    height: 100%;
    .form-input-label {
      .fw-500();
      .fs-18();
      line-height: 21px;
      color: @primary;
      margin-bottom: 10px;
    }
    .input-form-item {
      width: 270px;
      margin-bottom: 0;
      .form-input {
        width: 100%;
        padding: 7px 11px;
        border-radius: 2px;
        > div {
          > input {
            .fw-500();
            .fs-16();
            line-height: 19px;
            color: @primary;
          }
        }
      }
      button {
        height: 36px;
        padding: 4px 104px;
        .fw-500();
        .fs-16();
        line-height: 19px;
        color: #717171;
      }
      .form-select {
        .fw-500();
        color: #717171;
        height: 36px;
        > div:first-child {
          height: 100%;
          &:focus {
            border: 0;
            box-shadow: none;
            outline: 0;
          }
          > span:first-child {
            > input {
              height: 35px;
            }
          }
          > span:last-child {
            padding-top: 2px;
            color: #717171;
          }
        }
      }
    }
    .repo-file-btn {
      .flexAlignCenter();
      justify-content: flex-end;
      > button {
        height: 36px;
        padding: 4px 38px;
        .fw-600();
        .fs-18();
        line-height: 21px;
      }
    }
  }
}
.add-date-modal {
  .ant-modal-content {
    border-radius: 4px;
  }
  > div:nth-child(2) {
    > button:first-child {
      top: 15px;
      right: 20px;
      .ant-modal-close-x {
        height: 25px;
        width: 25px;
        line-height: 25px;
        border-radius: 20px;
        &:hover {
          background-color: #71717117;
        }
      }
    }
    > div:nth-child(2) {
      padding-bottom: 0;
      border-bottom: 0.5px solid #878787;
      > div {
        color: rgb(51 51 51);
        .fw-500();
        .fs-20();
        line-height: 22px;
        padding-bottom: 10px;
      }
    }
    > div:nth-child(3) {
      height: 306px;
      padding: 0;
      .add-date-picker {
        margin-left: 40px;
        margin-top: 4px;
      }
      .edit-date-title {
        color: #333;
        padding-left: 24px;
        display: block;
        padding-top: 4px;
        padding-bottom: 4px;
        border-bottom: 0.5px solid #878787;
      }
    }
    > div:last-child {
      direction: rtl;
      .flexAlignCenter();
      justify-content: space-around;
      background-color: #f6f6f6;
      > button {
        height: 36px;
        border-radius: 4px;
      }
      > button:first-child {
        background-color: @ghostbtnBack;
        width: 100px;
      }
      > button:last-child {
        margin: 0;
        width: 100px;
      }
    }
  }
}
.edit-date-modal {
  > div:nth-child(2) {
    > div:nth-child(3) {
      height: 336px;
    }
  }
}
.delete-date-modal {
  > div:nth-child(2) {
    > button:first-child {
      right: 20px;
      > span {
        line-height: 54px;
      }
    }
    > div:nth-child(2) {
      border: 0;
      padding-bottom: 0;
      > div {
        padding-bottom: 12px;
        border-bottom: 0.5px solid #878787;
        .fs-20();
      }
    }
    > div:nth-child(3) {
      height: 143px;
      padding: 0 30px;
      .delete-data-content {
        height: 100%;
        .flexAlignCenter();
        justify-content: center;
        text-align: center;
      }
    }
    > div:last-child {
      .flexAlignCenter();
      justify-content: flex-start;
      > button:last-child {
        margin-right: 30px;
      }
    }
  }
}
.edit-data-layer-modal {
  > div:nth-child(2) {
    > button:first-child {
      right: 3px;
      > span {
        line-height: 70px;
      }
    }
    > div:nth-child(2) {
      border: 0;
      padding-top: 20px;
      padding-bottom: 0;
      > div {
        padding-bottom: 12px;
        border-bottom: 0.5px solid #878787;
      }
    }
    > div:nth-child(3) {
      padding: 0 25px;
      height: 332px;
      .data-layer-table {
        > div {
          > div {
            > div {
              > div {
                > div {
                  > table {
                    > thead {
                      > tr {
                        border-radius: 2px;
                        > th {
                          background: @back;
                          padding: 7.5px 16px;
                        }
                        > th:first-child {
                          &::before {
                            display: none;
                          }
                        }
                      }
                    }
                    > tbody {
                      > tr {
                        border-radius: 2px;
                        > td {
                          height: 38px;
                          padding: 5.5px 16px;
                          border-bottom: 1px solid @ghostbtnBack;
                          background-color: @white;
                        }
                      }
                    }
                  }
                  &:last-child {
                    overflow-y: auto !important;
                    &::-webkit-scrollbar {
                      width: 5px;
                    }
                    &::-webkit-scrollbar-thumb {
                      background: @veryLightGray;
                      border-radius: 4px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .add-data-btn {
        height: 40px;
        border-top: 1px solid @ghostbtnBack;
        > button {
          height: 100%;
          .flexAlignCenter();
          gap: 5px;
        }
      }
    }
  }
}
.approve-modal {
  z-index: 99;
  height: 177px;
  .approve-modal-content {
    .flexAlignCenter();
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
    .modal-title {
      .fw-500();
      .fs-24();
      color: @titleColor;
      margin-bottom: 40px;
    }
    .notify-content {
      .fw-500();
      .fs-20();
      color: @titleColor;
      margin-bottom: 20px;
    }
    .modal-content-btn {
      > button {
        height: 36px;
        padding: 3px 50px;
        box-shadow: none;
      }
      > button:first-child {
        margin-right: 20px;
      }
      > button:last-child {
        background-color: @ghostbtnBack;
        border-color: @ghostbtnBack;
        &:hover,
        &:focus,
        &:focus:hover,
        &::after {
          border: 0;
          outline: 0;
          border-color: @ghostbtnBack;
          box-shadow: none;
          background-color: @ghostbtnBack;
        }
      }
    }
  }
}
.datalayer-dropdown {
  border-radius: 4px;
  > ul {
    padding: 0;
    width: 104px;
    .ant-dropdown-menu-item-divider {
      margin: 0;
      padding: 0;
    }
    .ant-dropdown-menu-title-content {
      .fs-12();
      .fw-500();
      line-height: 16px;
      color: #717171;
    }
  }
}

#date-popup {
  .ant-picker-header {
    border-bottom: 0;
    padding: 0 24px;
  }
  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none;
  }
  .ant-picker-header-prev-btn {
    padding-right: 10px;
  }
  .ant-picker-header-next-btn {
    padding-left: 10px;
  }
  .ant-picker-dropdown-placement-bottomLeft {
    left: 0 !important;
    top: 49px !important;
    > div {
      box-shadow: none !important;
      > div {
        border: 0 !important;
      }
    }
  }
  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      color: @white !important;
    }
  }
  .ant-picker-cell-inner {
    color: #a8a8a8;
    font-size: 10px;
    font-weight: 600;
  }
}
.add-date-main-popup {
  > div:first-child {
    top: 0px !important;
  }
}
.edit-date-main-popup {
  > div:first-child {
    top: 31px !important;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;