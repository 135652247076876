@import 'styles/variable.less';

.member-list-modal {
  > div:nth-child(2) {
    padding: 5px 25px 0px 25px;
    border-radius: 4px;
    > button:first-child {
      right: 6px;
    }
    > div:nth-child(2) {
      border-bottom: 0.5px solid #878787;
      padding: 16px 5px;
      padding-bottom: 12px;
      > div {
        .fw-500();
        .fs-20();
        color: @titleColor;
      }
    }
    > div:nth-child(3) {
      padding: 0;
    }
    .manager-avatar {
      .name-avatar {
        margin-right: 10px;
        background-color: @white;
        border: 0.5px solid @avatarTitle;
        line-height: 30px;
        > span {
          color: @avatarTitle;
        }
      }
      > span {
        color: @avatarTitle;
      }
    }
    .manager-role {
      color: @primary;
    }
    .member-list-table {
      > div {
        > div {
          > div {
            > div {
              > div {
                > table {
                  > thead {
                    > tr {
                      > th {
                        padding: 10px 5px 10px 5px;
                        color: @btnTitle;
                        background-color: @white;
                        .fw-600();
                        .fs-12();
                        &::before {
                          display: none;
                        }
                      }
                    }
                  }
                  > tbody {
                    > tr {
                      > td {
                        padding: 6px 6px 6px 5px;
                        .fw-500();
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;