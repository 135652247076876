@font-face {
  font-family: 'Manrope';
  src: local('ManropeRegular'), url('./fonts/ttf/ManropeRegular.ttf') format('truetype');
}

:root {
  --MNRPLR: 'Manrope' !important;
}

body {
  margin: 0;
  width: 100%;
  font-family: var(--MNRPLR);
  /* width: 100% !important; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: 'Manrope' !important;
}
