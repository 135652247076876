@import 'styles/variable.less';

.flex {
  display: flex;
}
.btn-content {
  justify-content: space-between !important;
  margin-top: 60px !important;
  > button:last-child {
    background-color: @primary !important;
    color: @white !important;
    font-size: 20px !important;
    line-height: 24px !important;
    text-shadow: none;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;