@import 'styles/variable.less';

.main-subheader-content {
  height: 56px;
  background: @darkGray;
  justify-content: space-between;
  align-items: center;
  .project-title {
    text-transform: uppercase;
    display: block;
    .fw-600();
    .fs-12();
    line-height: 14px;
    color: @subheader;
  }
  .project-data {
    .fw-500();
    .fs-14();
    line-height: 17px;
    color: @subheader;
  }
  .back-btn {
    .flexAlignCenter();
    cursor: pointer;
    padding-left: 10px;
    .back-title {
      padding-left: 6px;
      .fw-600();
      .fs-16();
      line-height: 19px;
      color: @subheader;
    }
  }
  .last-update-main {
    padding-right: 21px;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;