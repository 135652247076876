@primary: #6e3bd1;
@secondary: #f8f8f8;
@white: #fff;
@black: #000;
@black1: #030303;
@titleColor: #333333;
@darkGray: #f3f3f3;
@lightdarkGray: #f6f6f6;
@veryLightGray: #d9d9d9;
@verylightBlack: #ababab;
@green: #10af46;
@green1: #27ae60;
@lightBlack: #4a4a4a;
@avatarTitle: #727272;
@back: #f5f5f5;
@red: #d60000;
@red1: #fa2d00;
@roottableth: #3c3c3c;
@roottableth1: #252525;
@analyticBack: #fbfaff;
@ghostbtnBack: #e0e0e0;
@ghostbtnBack1: #e6e6e6;
@ghostbtnBack2: #ececec;
@notification: #bdbdbd;
@subheader: #8f8f8f;
@btnTitle: #a4a4a4;
@btnLabel: #747474;
@placeholder: #bfbfbf;
@formLabel: #4f4f4f;
@borderclr: #c7c7cc;
@orange: #f37200;
@orange1: #f2994a;
@purple: #BE66FF;
@tooltiptitle: #828282;
@lightGreen: #43ffdd;
@blueshade: #95969d;
@tlHeight: calc(100vh - 60px);
.border-1 {
  border: 1px solid @primary;
}
.fslh-12 {
  font-size: 12px;
  line-height: 14px;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px;
}
.fslh-14 {
  .fs-14();
  line-height: 17px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-28 {
  font-size: 28px;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.ff {
  font-family: 'Manrope' !important;
}
.flexAlignCenter {
  display: flex !important;
  align-items: center;
}
.flexJustifyCenter {
  display: flex;
  justify-content: center;
}
.flexJustifyStartAlignCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flexJustifyAlignCenter {
  .flexJustifyCenter();
  align-items: center;
}
