@import 'styles/variable.less';

.main-user-management {
  height: @tlHeight;
  background-color: @white;
  padding: 0 21px;
  .user-mng-head {
    padding: 15px 0;
    padding-bottom: 11px;
    .org-name-title {
      .fw-500();
      .fs-24();
      line-height: 29px;
      color: @titleColor;
    }
    .add-member-btn {
      background: @white;
      border: 1px solid @primary;
      border-radius: 4px;
      > span {
        .fw-500();
        .fs-16();
        line-height: 19px;
        color: @primary;
      }
    }
  }
  .main-user-table-tab {
    height: calc(100vh - 141px);
    > div:first-child {
      margin: 0;
      > div {
        &:last-child {
          .user-disable-enable {
            .user-switch-label {
              padding-right: 10px;
              .fw-500();
              .fs-16();
              line-height: 19px;
              color: @roottableth;
            }
            .member-switch {
              .ant-switch-handle {
                &::before {
                  background-color: #828282;
                }
              }
            }
            .member-switch.ant-switch-checked {
              .ant-switch-handle {
                &::before {
                  background-color: @white;
                }
              }
            }
          }
        }
        > div {
          > div:first-child {
            padding: 8px 21px;
            background: @analyticBack;
            > div {
              text-shadow: none;
              .tab-title {
                .fw-600();
                .fs-12();
                line-height: 14px;
                color: @primary;
              }
            }
          }
          > div:nth-child(2) {
            padding: 8px 21px;
            margin-left: 0;
            background: @analyticBack;
            > div {
              text-shadow: none;
              .tab-title {
                .fw-600();
                .fs-12();
                line-height: 14px;
                color: @primary;
              }
            }
          }
          .ant-tabs-tab-active {
            border: 1px solid @primary !important;
            box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.15) !important;
            border-radius: 4px 4px 0px 0px !important;
          }
        }
      }
    }
    > div:last-child {
      > div {
        height: 100%;
        .user-table-tabpane {
          background: @white;
          border: 1px solid @primary;
          border-radius: 0px 5px 5px 5px;
          height: 100%;
        }
      }
    }
  }
  .main-user-mng-table {
    > div {
      > div {
        > div {
          > div {
            > div {
              > table {
                > thead {
                  > tr {
                    > th {
                      padding: 12.5px 16px;
                      background-color: @white;
                      border-radius: 0px;
                      .fw-500();
                      .fs-16();
                      line-height: 19px;
                      color: @roottableth;
                      &:first-child {
                        padding-left: 50px;
                      }
                      &::before {
                        display: none;
                      }
                    }
                  }
                }
                > tbody {
                  > tr {
                    &:nth-child(even) {
                      background-color: @white;
                    }
                    &:nth-child(odd) {
                      background-color: #f9f9f9;
                    }
                    > td {
                      padding: 3.5px 16px;
                      height: 40px;
                      .user-name-mng {
                        padding-left: 34px;
                        > span:first-child {
                          margin-right: 8px;
                          text-transform: uppercase;
                          width: 28px;
                          height: 28px;
                          .fw-500();
                          .fs-12();
                          line-height: 29px;
                          color: @avatarTitle;
                          background-color: @white;
                          border: 0.5px solid @avatarTitle;
                          &:hover {
                            color: @white;
                            background-color: @avatarTitle;
                          }
                        }
                        > span:last-child {
                          .fw-500();
                          .fs-14();
                          line-height: 17px;
                          color: @avatarTitle;
                        }
                      }
                      .user-email-mng {
                        .fw-500();
                        .fs-14();
                        line-height: 17px;
                        color: @avatarTitle;
                      }
                      .user-project {
                        .flexAlignCenter();
                        justify-content: center;
                        > svg {
                          cursor: pointer;
                          margin-left: 3px;
                        }
                      }
                    }
                  }
                }
              }
            }
            > div:last-child {
              &::-webkit-scrollbar {
                width: 5px;
              }
              &::-webkit-scrollbar-thumb {
                background: @veryLightGray;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }
  }
  .main-project-table {
    > div {
      > div {
        > div {
          > div {
            > div {
              > table {
                > tbody {
                  > tr {
                    > td {
                      .project-member {
                        padding-left: 5px;
                      }
                    }
                    > td:first-child {
                      padding-left: 50px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@border-radius: 4px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;